// Import all of Bootstrap's CSS



$btn-border-radius: 2.5rem;
$btn-border-radius-lg: 2.5rem;
$font-family-sans-serif: Montserrat, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

$btn-border-radius: 2.5rem;
$btn-border-radius-lg: 2.5rem;
$font-family-sans-serif: "Fira Sans", Montserrat, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$primary: #a8a9ac;
$secondary: #7a013c;
$success: #3f3f3f;
$gray-100: #a8a9ac;
$gray-800: #3f3f3f;
$gray-600: #a8a9ac;
$info: #3f3f3f;

$input-border-radius: 0px;
// $input-padding-x: 5px;
// $input-padding-y: 2px;
// $input-line-height: 1;




/*$input-border-radius: 0rem;*/


.content-container {
    min-height: 630px;
}



@import "node_modules/bootstrap/scss/bootstrap";

// html,
// body,
// #app,
// #app>div {
//     height: 100%
// }

.top-menu {
    background: #8fadb7;
    color: white;
}

.top-menu>a {
    color: white !important;
}

.plan-picker {
    background-color: #3f3f3f !important;
    color: white;
}

.menu {
    background-color: white !important;
    color: black;
}

.bg-dark {
    /*background-color: #002e6d !important;*/
    background-color: white !important;

}

.btn-primary {
    color: #fff;
}

.btn-lg {
    text-transform: uppercase;
}

// body {
//     background-color: #ececec;
// }

h4 {
    text-transform: uppercase;
}

a,
a:focus,
button,
button:focus {
    outline: none;
}

.clicker {
    cursor: pointer;
}


.forbidden {
    opacity: 0.5;
    background-color: dimgrey;
    color: linen;
    cursor: not-allowed;
}

.full-square {
    border: 0;
    /*width: 100%;
    border-radius: 0rem !important;*/
}

.accordion-button {
    width: 100%;
    font-size: 1rem !important;
    align-items: center;
    border: 0;
    border-radius: 0;
    display: flex;
    overflow-anchor: none;
    position: relative;
    text-align: left;
}

.brokerVideo {
    width: 100%;
}

.form-label {
    font-weight: 600;
}

.videoWrapper {
    position: relative;
    padding-bottom: 56.25%;
    /* 16:9 */
    height: 0;
}

/* .videoWrapper iframe {
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
} */


.desktop-menu {
    padding-top: 0.36rem !important;
    padding-bottom: 1.1rem !important;
    font-size: 1rem !important;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.05), 0 1px 2px -1px rgb(0 0 0 / 0.1);
}

.phone-button {
    margin-top: 1rem !important;
    margin-right: 3rem !important;
}

.broker-logo {
    /*
    position: fixed;
    top: 10px;
    left: 80px;*/
    /*width: 300px;*/
    height: 53px;
    margin-left: 19px;
    margin-top: 2px;
    padding-left: 5px;
    /*height: 4.12rem;*/
}



.broker-logo-mobile {
    height: 2rem;
    margin-top: 1rem;
}

.footer-area {
    background-color: #3f3f3f;
    color: white;
    margin-bottom: -20px;
}

.footer-secure {
    color: rgb(228, 29, 94) !important;
}

.footer-area a:link {
    color: white;
    text-decoration: none;
}

.footer-area a:hover {
    color: #a8a9ac;
    text-decoration: none;
}

.custom-progress {
    border-radius: 3rem !important;
    /*background-color: rgb(228, 29, 94) !important;
    color: white;*/
}

.progress-bar {
    background: #7A013C;
}